import { ClientType } from '@/Clients/api/clients.types';
import { Box, Button, Card, CardBody, CardHeader, Flex, Heading, Text, useToast, VStack } from '@/core-components';
import { DraftableStatus } from '@/Core/core.types';
import { useCreateEstimateMutation } from '@/Estimates/api/estimates.api';
import { makeEstimate } from '@/Estimates/api/estimates.model';
import { useCreateInvoiceMutation } from '@/Invoices/api/invoices.api';
import { makeInvoice, makeLineItem } from '@/Invoices/api/invoices.model';
import { createErrorToast } from '@/Toaster/store/toaster.utils';
import { Currency } from '@/Treasuries/store/treasuries.utils';
import { faFileInvoice, faFileLines } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { DocumentLineItem } from '../../Invoices/api/invoices.types';
import {
  SuggestionDocument,
  useGetAISuggestionsEstimatesQuery,
  useGetAISuggestionsInvoicesQuery,
} from '../api/dashboard.api';

interface SuggestionCardProps {
  title: string;
  suggestions: SuggestionDocument[] | undefined;
  isLoading: boolean;
}

function formatCurrency(amount: number, currency: string) {
  return new Intl.NumberFormat('mk-MK', {
    style: 'currency',
    currency: currency,
  }).format(amount);
}

function calculateTotal(items: SuggestionDocument['lineItems'], discount: number | null = null) {
  const subtotal = items.reduce((total, item) => total + item.quantity * item.unitPrice, 0);
  if (discount) {
    return subtotal * (1 - discount / 100);
  }
  return subtotal;
}

function SuggestionCard({ title, suggestions, isLoading }: SuggestionCardProps) {
  const navigate = useNavigate();
  const toast = useToast();
  const [createInvoice] = useCreateInvoiceMutation();
  const [createEstimate] = useCreateEstimateMutation();

  const onCreateDocument = async (suggestion: SuggestionDocument) => {
    try {
      const mappedLineItems = suggestion.lineItems.map((item) => ({
        ...makeLineItem(item as unknown as Partial<DocumentLineItem>),
      }));

      if (suggestion.type === 'invoice') {
        const invoice = makeInvoice({
          client: {
            ...suggestion.client,
            type: ClientType.Company,
            invoices: [],
            estimates: [],
            payments: [],
            expenses: [],
          },
          project: {
            ...suggestion.project,
            status: DraftableStatus.Created,
            invoices: [],
            estimates: [],
            payments: [],
            expenses: [],
          },
          lineItems: mappedLineItems,
          dueDate: suggestion.dueDate,
          notes: suggestion.notes,
          currency: suggestion.currency as Currency,
        });
        const result = await createInvoice(invoice);
        if ('error' in result) {
          toast(createErrorToast({ description: 'Грешка при креирање на Фактура' }));
          return;
        }
        navigate(`/invoices/${result.data.id}/edit`);
      } else {
        const estimate = makeEstimate({
          client: {
            ...suggestion.client,
            type: ClientType.Company,
            status: DraftableStatus.Draft,
            invoices: [],
            estimates: [],
            payments: [],
            expenses: [],
          },
          project: {
            ...suggestion.project,
            status: DraftableStatus.Draft,
            invoices: [],
            estimates: [],
            payments: [],
            expenses: [],
          },
          lineItems: mappedLineItems,
          dueDate: suggestion.dueDate,
          notes: suggestion.notes,
          discount: suggestion.discount || undefined,
          currency: suggestion.currency as Currency,
        });
        const result = await createEstimate(estimate);
        if ('error' in result) {
          toast(createErrorToast({ description: 'Грешка при креирање на Пресметка' }));
          return;
        }
        navigate(`/estimates/${result.data.id}/edit`);
      }
    } catch (error) {
      console.error(error);
      toast(createErrorToast({ description: 'Грешка при креирање на документ' }));
    }
  };

  if (isLoading) {
    return (
      <Card variant="outline" boxShadow="sm">
        <CardHeader>
          <Heading size="md">{title}</Heading>
        </CardHeader>
        <CardBody>
          <Text>Вчитување...</Text>
        </CardBody>
      </Card>
    );
  }

  if (!suggestions?.length) {
    return (
      <Card variant="outline" boxShadow="sm">
        <CardHeader>
          <Heading size="md">{title}</Heading>
        </CardHeader>
        <CardBody>
          <Text>Нема достапни предлози</Text>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card variant="outline" boxShadow="sm">
      <CardHeader>
        <Heading size="md">{title}</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          {suggestions.map((suggestion) => {
            const total = calculateTotal(suggestion.lineItems, suggestion.discount);
            const documentNumber = suggestion.type === 'invoice' ? suggestion.invoiceNumber : suggestion.estimateNumber;
            const clientName = suggestion.client?.name || 'Unknown Client';
            const projectName = suggestion.project?.name || 'Unknown Project';

            return (
              <Box
                key={documentNumber}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                _hover={{ bg: 'gray.50' }}
                transition="all 0.2s"
              >
                <Flex justify="space-between" align="center" mb={3}>
                  <Heading size="sm" color="blue.600">
                    {clientName}
                  </Heading>
                  <Text fontWeight="bold" color="green.500">
                    {formatCurrency(total, suggestion.currency)}
                  </Text>
                </Flex>
                <Text color="gray.600" mb={2}>
                  Проект: {projectName}
                </Text>
                <Flex justify="space-between" color="gray.600" mb={3}>
                  <Text>Доспева: {new Date(suggestion.dueDate).toLocaleDateString()}</Text>
                  <Text>{suggestion.lineItems.length} ставки</Text>
                </Flex>
                {suggestion.notes && (
                  <Text mb={3} fontSize="sm" color="gray.500" fontStyle="italic">
                    {suggestion.notes}
                  </Text>
                )}
                <Button
                  leftIcon={<FontAwesomeIcon icon={suggestion.type === 'invoice' ? faFileInvoice : faFileLines} />}
                  colorScheme="blue"
                  size="sm"
                  onClick={() => onCreateDocument(suggestion)}
                >
                  Креирај {suggestion.type === 'invoice' ? 'Фактура' : 'Пресметка'}
                </Button>
              </Box>
            );
          })}
        </VStack>
      </CardBody>
    </Card>
  );
}

export function DashboardSuggestions() {
  const { data: invoiceSuggestions, isLoading: isLoadingInvoices } = useGetAISuggestionsInvoicesQuery();
  const { data: estimateSuggestions, isLoading: isLoadingEstimates } = useGetAISuggestionsEstimatesQuery();

  return (
    <Flex gap={4}>
      <Box flex={1}>
        <SuggestionCard title="Предложени Фактури" suggestions={invoiceSuggestions} isLoading={isLoadingInvoices} />
      </Box>
      <Box flex={1}>
        <SuggestionCard title="Предложени Пресметки" suggestions={estimateSuggestions} isLoading={isLoadingEstimates} />
      </Box>
    </Flex>
  );
}
