import { BaseQueryFn, createApi, retry } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { http } from './http';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    { status: number; data: { message?: string; error?: string; errorCode?: string } }
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result: unknown = await http
        .request({ url, method, data, params, headers: headers as AxiosRequestHeaders })
        .then(({ data }) => data);
      // @ts-expect-error FUCK TYPESCRIPT
      return { ...result };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.status,
          data: (err as unknown as { data: { message?: string; error?: string; errorCode?: string } }).data || {
            message: err.message,
          },
        } as { status: number; data: { message?: string; error?: string; errorCode?: string } },
      };
    }
  };

export enum TagType {
  Audits = 'Audits',
  AuthUser = 'AuthUser',
  Company = 'Company',
  Clients = 'Clients',
  Invoices = 'Invoices',
  Estimates = 'Estimates',
  Projects = 'Projects',
  Products = 'Products',
  Expenses = 'Expenses',
  Notifications = 'Notifications',
  Trash = 'Trash',
  CashPayments = 'CashPayments',
  ExpenseCategories = 'ExpenseCategories',
  DashboardCounters = 'DashboardCounters',
  DashboardInvoicesChart = 'DashboardInvoicesChart',
  DashboardExpensesChart = 'DashboardExpensesChart',
  DashboardPaymentsChart = 'DashboardPaymentsChart',
  DashboardTopClientsChart = 'DashboardTopClientsChart',
  DashboardTopCategoriesChart = 'DashboardTopCategoriesChart',
  DashboardAISuggestions = 'DashboardAISuggestions',
}

const baseQuery = retry(axiosBaseQuery(), { maxRetries: 2 });

export const api = createApi({
  reducerPath: 'brojkiApi',
  baseQuery,
  tagTypes: Object.values(TagType),
  endpoints: () => ({}),
  keepUnusedDataFor: 120,
});
