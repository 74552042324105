export const ApiEndpoints = {
  AuditsUrl: 'api/audits',
  UserProfileUrl: 'api/me',
  EmailVerificationInitiateUrl: 'api/me/send-email-verification',
  LoginUrl: 'login',
  LogoutUrl: 'logout',
  CSRFCookie: 'sanctum/csrf-cookie',
  AuthenticateUrl: 'api/authenticate',
  SignupUrl: 'api/signup',
  CompanyUrl: 'api/companies',
  NotificationsUrl: 'api/notifications',
  ClientsUrl: 'api/clients',
  ClientsSearchUrl: 'api/clients/search',
  ProjectsUrl: 'api/projects',
  InvoicesUrl: 'api/invoices',
  EstimatesUrl: 'api/estimates',
  InvoicePaymentsUrl: 'api/invoice-payments',
  ExpensesUrl: 'api/expenses',
  ExpenseCategoriesUrl: 'api/expense-categories',
  CashPaymentsUrl: 'api/cash-payments',
  ProductsUrl: 'api/products',
  SpotlightSearchUrl: 'api/spotlight/search',
  SpotlightInvoicesUrl: 'api/spotlight/invoices',
  SpotlightClientsUrl: 'api/spotlight/clients',
  SpotlightProjectsUrl: 'api/spotlight/projects',
  SpotlightProductsUrl: 'api/spotlight/products',
  SpotlightExpensesUrl: 'api/spotlight/expenses',
  SpotlightPaymentsUrl: 'api/spotlight/payments',
  DashboardCountersUrl: 'api/dashboard/counters',
  DashboardInvoicesUrl: 'api/dashboard/invoices',
  DashboardPaymentsUrl: 'api/dashboard/payments',
  DashboardExpensesUrl: 'api/dashboard/expenses',
  DashboardTopClientsUrl: 'api/dashboard/top-clients',
  DashboardTopCategoriesUrl: 'api/dashboard/top-categories',
  AISuggestionsInvoicesUrl: 'api/ai-suggestions/invoices',
  AISuggestionsEstimatesUrl: 'api/ai-suggestions/estimates',
  TrashUrl: 'api/trash',
  PrintInvoiceUrl: 'api/print-invoice',
  PrintEstimateUrl: 'api/print-estimate',
  PrintExpenseUrl: 'api/print-expense',
  PrintPaymentUrl: 'api/print-payment',
  MailInvoiceUrl: 'api/mail-invoice',
  MailEstimateUrl: 'api/mail-estimate',
};
