import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { TODO } from '../../Core/core.types';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';

const {
  DashboardCountersUrl,
  DashboardInvoicesUrl,
  DashboardExpensesUrl,
  DashboardPaymentsUrl,
  DashboardTopCategoriesUrl,
  DashboardTopClientsUrl,
  AISuggestionsInvoicesUrl,
  AISuggestionsEstimatesUrl,
} = ApiEndpoints;

export interface DashboardFilters {
  client?: string; // client id
  q?: string;
}

export interface LineItem {
  name: string;
  quantity: number;
  unitPrice: number;
  description: string;
  unit: string;
  product: {
    id: string;
    name: string;
    sku_code: string;
  };
}

export interface Client {
  id: string;
  name: string;
}

export interface Project {
  id: string;
  name: string;
}

export interface SuggestionDocument {
  invoiceNumber?: string;
  estimateNumber?: string;
  invoicedDate?: string;
  estimatedAt?: string;
  dueDate: string;
  type: 'invoice' | 'estimate';
  notes: string;
  discount: number | null;
  currency: string;
  client: Client;
  project: Project;
  lineItems: LineItem[];
}

export interface AISuggestionsResponse {
  invoices: SuggestionDocument[];
  estimates: SuggestionDocument[];
}

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboardCounters: build.query<TODO, void>({
      query: () => ({ url: DashboardCountersUrl, method: 'GET' }),
      providesTags: () => [TagType.DashboardCounters],
    }),
    getDashboardInvoices: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardInvoicesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardInvoicesChart],
    }),
    getDashboardExpenses: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardExpensesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardExpensesChart],
    }),
    getDashboardPayments: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardPaymentsUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardPaymentsChart],
    }),
    getDashboardTopClients: build.query<TODO, { year: number }>({
      query: (filter) => ({ url: DashboardTopClientsUrl, method: 'GET', params: filter }),
      providesTags: () => [TagType.DashboardTopClientsChart],
    }),
    getDashboardTopCategories: build.query<TODO, { year: number }>({
      query: (filter) => ({ url: DashboardTopCategoriesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardTopCategoriesChart],
    }),
    getAISuggestionsInvoices: build.query<SuggestionDocument[], void>({
      query: () => ({ url: AISuggestionsInvoicesUrl, method: 'GET' }),
      transformResponse: (response: { invoices: SuggestionDocument[] } | undefined) => response?.invoices || [],
      providesTags: () => [TagType.DashboardAISuggestions],
    }),
    getAISuggestionsEstimates: build.query<SuggestionDocument[], void>({
      query: () => ({ url: AISuggestionsEstimatesUrl, method: 'GET' }),
      transformResponse: (response: { estimates: SuggestionDocument[] } | undefined) => response?.estimates || [],
      providesTags: () => [TagType.DashboardAISuggestions],
    }),
  }),
});

export const dashboardCountersLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(dashboardApi.endpoints.getDashboardCounters.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  return promise;
};

export const {
  useGetDashboardCountersQuery,
  useGetDashboardInvoicesQuery,
  useGetDashboardExpensesQuery,
  useGetDashboardPaymentsQuery,
  useGetDashboardTopClientsQuery,
  useGetDashboardTopCategoriesQuery,
  useGetAISuggestionsInvoicesQuery,
  useGetAISuggestionsEstimatesQuery,
} = dashboardApi;
